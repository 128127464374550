import React, { Component } from 'react';
import { OpenSheetMusicDisplay as OSMD, PointF2D   } from 'opensheetmusicdisplay';

export interface OSMDReactProps {
  autoResize?: boolean;
  drawTitle?: boolean;
  file: string;
  measureIndex: number | undefined;
  onLoadedMetadata: Function;
  onMeasureClicked: Function;
}


export default class OSMDReact extends Component<OSMDReactProps, {}> {
  portals = [];
  osmd?: OSMD;
  eOSMDDiv?: HTMLElement;

  constructor(public props: any) {
    super(props);
    this.state = { dataReady: false };
  }

  setupOsmd() {
    const options = {
      autoResize: this.props.autoResize !== undefined ? this.props.autoResize : true,
      drawTitle: this.props.drawTitle !== undefined ? this.props.drawTitle : true,
      cursorsOptions: [{type: 3, color: "#FF6329", follow: true, alpha: 0.2},],
      followCursor: true,
      renderSingleHorizontalStaffline: window.innerWidth <= 768
    }

    this.osmd = new OSMD(this.eOSMDDiv!, options); // TODO: lacking the current on div

    this.osmd.RenderingManager.addListener({
      userDisplayInteraction: (relativePosition: PointF2D, positionInSheetUnits: PointF2D, type: any) => {
        const measureIndex: number | undefined = this.osmd?.GraphicSheet.GetNearestVoiceEntry(positionInSheetUnits).parentStaffEntry.parentMeasure.MeasureNumber;
        this.moveCursorToMeasure(measureIndex);
        this.props.onMeasureClicked(measureIndex);
      }
    });

    this.osmd.load(this.props.file).then(
      () => {
        this.props.onLoadedMetadata(this.osmd?.Sheet.LastMeasureNumber);
        this.osmd?.render();
    });
  }

  moveCursorToMeasure(measureIndex: number | undefined) {
    if (measureIndex == null || this.osmd == null || this.osmd.cursors.length == null || this.osmd.cursors.length === 0) {
      return;
    }

    this.osmd.Sheet.SelectionStart = this.osmd.Sheet.SourceMeasures[0].AbsoluteTimestamp
    this.osmd.cursors[0].reset();
        
    while (this.osmd.cursors[0].iterator.CurrentMeasureIndex < measureIndex) {
      this.osmd.cursors[0].next();
    }

    this.osmd.cursors[0].show();
  }

  resize() {
    this.forceUpdate();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  componentDidUpdate(prevProps: OSMDReactProps) {
    if (this.props.drawTitle !== prevProps.drawTitle) {
      this.setupOsmd();
    } else if (this.props.measureIndex !== prevProps.measureIndex) {
      this.moveCursorToMeasure(this.props.measureIndex);
    } else {
      this.osmd?.load(this.props.file).then(() => {
        this.osmd?.render()
      } );
    }
    window.addEventListener('resize', this.resize)
  }

  shouldComponentUpdate(nextProps: Readonly<OSMDReactProps>, nextState: Readonly<{}>, nextContext: any): boolean {
    return nextProps.measureIndex !== this.props.measureIndex;
  }

  componentDidMount() {
    this.setupOsmd();
  }

  render() {
    return React.createElement<any>("div", {
        ref: (e: HTMLElement) => {
            this.eOSMDDiv = e;
        }
    }, this.portals); 
  }
}
