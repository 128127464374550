import { Component } from 'react';

import WaveSurfer from 'wavesurfer.js';
import styles from './waveformreact.module.css';

export interface WaveformReactProps {
    file: string;
    playing: boolean;
    measureClicked: boolean;
    onWaveformUpdatedFollowingMeasureClicked: Function;
    onWaveformClicked: Function;
    onPlayButtonClicked: Function;
    timestamp: number;
    duration?: number;
}

interface WaveformReactState {
    waveformClicked: boolean;
}


export default class WaveformReact extends Component<WaveformReactProps, WaveformReactState> {
    waveform?: WaveSurfer;

    constructor(props: WaveformReactProps) {
        super(props);
        this.state = { 
            waveformClicked: false
        }
    }

    componentDidMount() {    
        this.waveform = WaveSurfer.create({
          cursorWidth: 1,
          barHeight: 4,
          container: '#waveform',
          height: 75,
          progressColor: '#FF6329',
          responsive: true, 
          waveColor: '#FFDFD4',
          cursorColor: 'transparent'        
        });
    
        this.waveform.load(this.props.file);
        this.waveform.setMute(true);

        this.waveform.drawer.on('click', () => {
            this.setState({ waveformClicked: true });
        });

        this.waveform.on('seek', (progress: number) => {
            if (this.waveform === undefined) return;

            if (this.state.waveformClicked) {
                this.props.onWaveformClicked(progress * this.waveform.getDuration());
                this.setState({ waveformClicked: false });
            }
        });
    };

    componentDidUpdate(prevProps: WaveformReactProps) {
        if (this.props.file !== prevProps.file) {
            this.waveform?.load(this.props.file);
        }

        if (this.props.playing !== prevProps.playing) {
            if (this.props.playing) {
                this.waveform?.setCurrentTime(this.props.timestamp);
                this.waveform?.play();
                
            } else {
                this.waveform?.pause();
            } 
        }

        if (this.props.measureClicked) {
            this.waveform?.setCurrentTime(this.props.timestamp);
            this.props.onWaveformUpdatedFollowingMeasureClicked();
        }
    }

    shouldComponentUpdate(nextProps: Readonly<WaveformReactProps>, nextState: Readonly<{}>, nextContext: any): boolean {
        return nextProps.measureClicked || 
        nextProps.playing !== this.props.playing || 
        nextProps.file !== this.props.file || 
        nextProps.timestamp !== this.props.timestamp || 
        nextProps.duration !== this.props.duration;
    }

    handlePlay = () => {
        this.props.onPlayButtonClicked(!this.props.playing);
    };

    formattedTimestamp = () => {
        var formattedDuration = '-';

        if (this.props.duration !== undefined && this.props.duration < 60) {
            formattedDuration = `${this.props.duration.toFixed(0)}`;
        } else if (this.props.duration !== undefined) {
            formattedDuration = `${(Math.floor(this.props.duration/60)).toFixed(0)}:${(this.props.duration%60).toFixed(0)}`;
        }
        
        var formattedTs = '-';

        if (this.props.timestamp < 60) {
            formattedTs = `${this.props.timestamp.toFixed(1)}`
        } else {
            formattedTs = `${(Math.floor(this.props.timestamp / 60)).toFixed(0)}:${(this.props.timestamp%60).toFixed(1)}`
        }

        return `${formattedTs} / ${formattedDuration}`
    }


    render() {
        return (
          <div className={styles.waveformcontainer}>
            <div>
                <div className={styles.playbutton} onClick={this.handlePlay} >
                    {this.props.playing? <Pause /> : <Play />}
                </div>
            </div>
            <div className={styles.waveformwrapper}>
                <div id="waveform" className={styles.waveform}/>
                <p className={styles.timestamp}>{this.formattedTimestamp()}</p>
            </div>
          </div>
        );
    }
}

const Pause = () => {
    return (
        <svg className={styles.button} viewBox="0 0 60 60">
            <polygon points="0,0 15,0 15,60 0,60" />
            <polygon points="25,0 40,0 40,60 25,60" />
        </svg>
    )
}

const Play = () => {
    return (
        <svg className={styles.button} viewBox="0 0 60 60">
            <polygon points="0,0 50,30 0,60" />
        </svg>
    )
}
