import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from "firebase/firestore";

const config = {
    apiKey: "AIzaSyCStoHExCtg3Br8om0PBChoYGNG9HEeUCg",
    authDomain: "classically-b73c5.firebaseapp.com",
    projectId: "classically-b73c5",
    storageBucket: "classically-b73c5.appspot.com",
    messagingSenderId: "167063894119",
    appId: "1:167063894119:web:49425cf773d8d2939dfcc2",
    measurementId: "G-YBGQSRP8R4"
};

export const app = initializeApp(config);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
