import styles from './composition-list.module.css';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { collection, getDocs, query } from "firebase/firestore"; 
import { db } from './Firebase';
import {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Button, { ButtonProps } from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import ForumIcon from '@mui/icons-material/Forum';
import { ReactComponent as MusicistImage } from './images/musicist.svg';

export function CompositionList() {
    const [compositionList, setCompositionList]: [{id: string, firstName: string, lastName: string, title: string }[], any] = useState([]);
    let navigate = useNavigate();

    const fetchCompositionList = async () => {
        const querySnapshot = await getDocs(query(collection(db, "compositions")));
        var compositions: {id: string, firstName: string, lastName: string, title: string }[] = [];
        querySnapshot.forEach((doc) => {
            compositions.push({
                id: doc.id, 
                title: doc.data().title, 
                firstName: doc.data()['composer-first-name'], 
                lastName: doc.data()['composer-last-name']})
        });

        if (compositions.length > 0) {
            setCompositionList(compositions);
        }
    }

    useEffect(() => {
        fetchCompositionList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const listItemClicked = (index: number) => {
        navigate(`/${compositionList[index].id}`, { replace: true });
    }

    const newRecordingClicked = () => {
        var win = window.open('https://form.jotform.com/221391781501047', '_blank');
        win?.focus();
    }

    const discordClicked = () => {
        var win = window.open('https://discord.com/invite/mXU4dCrb', '_blank');
        win?.focus();
    }

    const compositionListItems = () => {
        let result: JSX.Element[] = [];
    
        for (let i = 0; i < compositionList.length; i++) {
            const text: string = `${compositionList[i].lastName} (${compositionList[i].firstName}) - ${compositionList[i].title}`
            result.push(
                <ListItem disablePadding key={`composition-list-item-${i}`}>
                    <ListItemButton key={`composition-list-item-button-${i}`} onClick={() => {listItemClicked(i);}}>
                        <ListItemText primary={text} key={`composition-list-item-text-${i}`}/>
                    </ListItemButton>
                </ListItem>
            );
        }

        return result;
    }

    const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
        color: '#ffffff',
        borderColor: '#FF6329',
        backgroundColor: '#FF6329',
        textColor: '#FF6329',
        '&:hover': {
            borderColor: '#FF6329',
            color: '#FF6329'
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
          },
    }));

    return(
        <div>
            <div className={styles.navbar}>
                <Stack spacing={1} direction="row" className={styles.navbarlogostack}>
                    <MusicistImage className={styles.musicistimage}/>
                    <p className={styles.tagline}>Play like the best musicians</p>
                </Stack>
                <Stack spacing={1} direction="row" className={styles.navbarbuttonstack}>
                    <ColorButton 
                        variant="outlined" 
                        onClick={() => {newRecordingClicked();}}
                        startIcon={<CloudUploadIcon />}
                        >Upload recording
                    </ColorButton>
                    <ColorButton 
                        variant="outlined" 
                        onClick={() => {discordClicked();}}
                        startIcon={<ForumIcon />}
                        >Join community
                    </ColorButton>
                </Stack>
            </div>
            <div>
                <div className={styles.compositionlistcontainer}>
                        <List>
                            {compositionListItems()}
                        </List>
                </div>
            </div>
        </div>
    )
}