import OSMDReact from './lib/OSMDReact'
import ReactAudioPlayer from 'react-audio-player';
import {useState, useEffect} from 'react';
import styles from './composition.module.css';
import WaveformReact from './lib/WaveformReact';
import { db } from './Firebase';
import { collection, getDocs, doc, updateDoc, deleteDoc, addDoc, query, where } from "firebase/firestore"; 
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useParams } from "react-router-dom";
import Stack from '@mui/material/Stack';
import { ReactComponent as MusicistImage } from './images/musicist.svg';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

export function Composition() {
    let navigate = useNavigate();
    const compositionId = useState(useParams<{ compositionId: string }>().compositionId)[0];
    var rap: ReactAudioPlayer | null;
    const [measureIndex, setMeasureIndex]: [number?, any?] = useState(undefined);
    const [audioDuration, setAudioDuration]: [number?, any?] = useState(undefined);
    const [measuresCount, setMeasuresCount]: [number?, any?] = useState(undefined);
    const [playerTimestamp, setPlayerTimestamp]: [number, any] = useState(0);
    const [explicitSyncPoints, setExplicitSyncPoints]: [{id: string, measure: number, ts: number}[], any] = useState([]);
    const [allSyncPoints, setAllSyncPoints]: [[number, number][], any] = useState([]); // [measure index, time]
    const [playing, setPlaying]: [boolean, any] = useState(false);
    const [measureClicked, setMeasureClicked]: [boolean, any] = useState(false);
    const [recordingUrl, setRecordingUrl]: [string?, any?] = useState(undefined);
    const [compositionUrl, setCompositionUrl]: [string?, any?] = useState(undefined);
    const [performanceList, setPerformanceList]: [{id: string, title: string}[], any] = useState([]);
    const [currentPerformanceIndex, setCurrentPerformanceIndex]: [number, any] = useState(0);
    const editMode = false;

    const syncPtUrl = `sync-points`;

    const fetchPerformanceList = async () => {
        const q = query(collection(db, "performances"), where("composition", "==", compositionId));
        const querySnapshot = await getDocs(q);
        var performances: {id: string, title: string}[] = [];
        querySnapshot.forEach((doc) => {
            performances.push({id: doc.id, title: doc.data().title})
        });

        if (performances.length > 0) {
            setPerformanceList(performances);
            fetchPerformanceRecording(performances[0]);
            fetchSyncPoints(performances[0]);
        }
    }

    const fetchPerformanceRecording = (performance: {id: string, title: string}) => {
        const storage = getStorage();
        const pathReference = ref(storage, `performances/${performance.id}.mp3`);

        getDownloadURL(pathReference)
            .then((url) => {
                setRecordingUrl(url);
            })
            .catch((error) => {
                // Handle any errors
            });
    }

    const fetchCompositionPart = () => {
        const storage = getStorage();
        const pathReference = ref(storage, `compositions/${compositionId}.mxl`);

        getDownloadURL(pathReference)
            .then((url) => {
                setCompositionUrl(url);
            })
            .catch((error) => {
                // Handle any errors
            });
    }

    useEffect(() => {
        fetchCompositionPart();
        fetchPerformanceList();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchSyncPoints = async (performance: {id: string, title: string}) => {

        const q = query(collection(db, "sync-points"), where("performance", "==", performance.id));
        const querySnapshot = await getDocs(q);
        var syncPts: {id: string, measure: number, ts: number}[] = [];
        querySnapshot.forEach((doc) => {
            syncPts.push({id: doc.id, measure: doc.data().measure, ts: doc.data().ts})
        });

        syncPts.sort((a,b) => (a.ts > b.ts) ? 1 : ((b.ts > a.ts) ? -1 : 0)) // Sort by
        setExplicitSyncPoints(syncPts);
        processSyncPoints(syncPts);
    };

    const processSyncPoints = (syncPts: {id: string, measure: number, ts: number}[]) => {
        const newSyncPts: [number, number][] = [];

        for (var i = 0; i < syncPts.length - 1; i++) {
            const curPt = syncPts[i];
            const nextPt = syncPts[i+1];

            newSyncPts.push([curPt.measure, curPt.ts]);

            const missingCount = nextPt.measure - curPt.measure - 1;
            if (missingCount <= 0) { continue; }
            const avgMeasureDuration = (nextPt.ts - curPt.ts) / (missingCount + 1);
            
            for (var j = 0; j < missingCount; j++) {
                newSyncPts.push([curPt.measure + j + 1, curPt.ts + (j + 1) * avgMeasureDuration]);
            }
        }

        newSyncPts.push([syncPts[syncPts.length - 1].measure, syncPts[syncPts.length - 1].ts]);

        setAllSyncPoints(newSyncPts);
    }

    const onAudioListen = (time: number) => {
        setPlayerTimestamp(time);
        updateMeasure(time);
    };

    const onAudioLoadedMetadata = (e: Event) => {
        if (rap?.audioEl === undefined || rap?.audioEl.current === null) return;
        setAudioDuration(rap?.audioEl.current.duration);
    };

    const onSheetLoadedMetadata = (measuresCount: number) => {
        setMeasuresCount(measuresCount);
    }

    const onAudioPause = (e: Event) => {
        setPlaying(false);
    };

    const onAudioPlay = (e: Event) => {
        setPlaying(true);
    };

    const onMeasureClicked = (measureIndex: number | undefined) => {
        if (rap === null || measureIndex === undefined || rap?.audioEl === undefined || rap?.audioEl.current === null) return;

        var measureIdxInclReps = 0;
        while (measureIndex > allSyncPoints[measureIdxInclReps][0]) {
            measureIdxInclReps++;
        }
        const newTime = allSyncPoints[measureIdxInclReps][1];

        rap.audioEl.current.currentTime = newTime;
        setPlayerTimestamp(newTime);
        setMeasureClicked(true);
    };

    const onWaveformClicked = (newTime: number) => {
        if (rap === null || rap.audioEl === undefined || rap.audioEl.current === null) return;

        rap.audioEl.current.currentTime = newTime;
        setPlayerTimestamp(newTime);
        updateMeasure(newTime);
    }

    const onWaveformUpdatedFollowingMeasureClicked = () => {
        setMeasureClicked(false);
    }

    const onPlayButtonClicked = (playing: boolean) => {
        if (playing) {
            rap?.audioEl.current?.play();
        } else {
            rap?.audioEl.current?.pause();  
        }
    }

    const updateMeasure = (time: number) => {
        if (audioDuration == null || measuresCount == null) { return; }

        var curPtIdx = 0;
        var nextPtIdx = 1;
        while (true) {
            if (curPtIdx > allSyncPoints.length) { break; }

            if (time >= allSyncPoints[curPtIdx][1] && (allSyncPoints.length < nextPtIdx + 1 || time < allSyncPoints[nextPtIdx][1])) { 
                if (allSyncPoints[curPtIdx][0] !== measureIndex) {
                    setMeasureIndex(allSyncPoints[curPtIdx][0]);
                    return;
                }

                break; 
            } 

            curPtIdx++;
            nextPtIdx++;
        }
    }

    const updateSyncPt = async (index: number) => {
        const id = explicitSyncPoints[index].id;
        const ref = doc(db, syncPtUrl, id);

        await updateDoc(ref, {
            measure: explicitSyncPoints[index].measure,
            ts: explicitSyncPoints[index].ts,
            performance: performanceList[currentPerformanceIndex].id
        });
    }

    const createSyncPt = async (index: number) => {
        await addDoc(collection(db, syncPtUrl), {
            measure: explicitSyncPoints[index].measure,
            ts: explicitSyncPoints[index].ts,
            performance: performanceList[currentPerformanceIndex].id
        });
    };

    const didClickEditSyncPoint = (index: number) => {
        if (explicitSyncPoints[index].id.length === 0) {
            createSyncPt(index);
        } else {
            updateSyncPt(index);
        }
    }

    const deleteSyncPt = async (index: number) => {
        const id = explicitSyncPoints[index].id;
        await deleteDoc(doc(db, syncPtUrl, id));
    }

    const didClickDeleteSyncPoint = (index: number) => {
        deleteSyncPt(index);
        var syncPts = explicitSyncPoints;
        syncPts.splice(index, 1);
        setExplicitSyncPoints([...syncPts]);
    }

    const didClickAddSyncPoint = () => {
        var syncPts = explicitSyncPoints;
        syncPts.push({measure: 0, ts: 0, id: ""});
        setExplicitSyncPoints([...syncPts]);
    }

    const didEditMeasureTextField = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        var syncPts = explicitSyncPoints;
        const regex: RegExp = /[0-9]+/

        if (regex.test(e.target.value) && !isNaN(+e.target.value)) {
            syncPts[index].measure = +e.target.value;
        }
        
        setExplicitSyncPoints(syncPts);
    }

    const didEditTimestampTextField = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        var syncPts = explicitSyncPoints;
        const regex: RegExp = /[0-9]+(.[0-9]+)?/

        if (regex.test(e.target.value) && !isNaN(+e.target.value)) {
            syncPts[index].ts = +e.target.value;
        }
        
        setExplicitSyncPoints(syncPts);
    }

    const syncPointsLabels = () => {
        let result: JSX.Element[] = [];

        for(let i = 0; i < explicitSyncPoints.length; i++) {
            result.push(
                <div key={`sync-points-${i}`} className={styles.syncpointtextfieldcontainer}>
                    <TextField 
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]+' }} 
                        onChange={(e) => didEditMeasureTextField(e, i) } 
                        className={styles.textfieldts} 
                        key={`sync-point-measure-field-${i}`}
                        id="outlined-basic" 
                        variant="outlined" 
                        defaultValue={explicitSyncPoints[i].measure} 
                        size="small"/>
                    <TextField 
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]+(.[0-9]+)?' }} 
                        onChange={(e) => didEditTimestampTextField(e, i) } 
                        className={styles.textfieldmeasure} 
                        key={`sync-point-ts-field-${i}`}
                        id="outlined-basic" 
                        variant="outlined" 
                        defaultValue={explicitSyncPoints[i].ts} 
                        size="small"/>
                    <IconButton key={`sync-point-edit-icon-${i}`} onClick={() => didClickEditSyncPoint(i)}><SaveIcon /></IconButton>
                    <IconButton key={`sync-point-delete-icon-${i}`} onClick={() => didClickDeleteSyncPoint(i)}><DeleteIcon /></IconButton>
                </div>
            );
        }

        return result;
    }

    const handleChange = (event: SelectChangeEvent) => {
        const i: number = +event.target.value;

        setCurrentPerformanceIndex(i);
        fetchPerformanceRecording(performanceList[i]);
        fetchSyncPoints(performanceList[i]);
    };

    const performanceMenuItems = () => {
        let result: JSX.Element[] = [];

        for(let i = 0; i < performanceList.length; i++) {
            result.push(
                <MenuItem key={`performance-menu-item-${i}`} value={i}>{`Recording ${i+1}: ${performanceList[i].title}`}</MenuItem>
            );
        }

        return result;
    }

    const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
        color: '#ffffff',
        borderColor: '#FF6329',
        backgroundColor: '#FF6329',
        textColor: '#FF6329',
        height: '40px',
        '&:hover': {
            borderColor: '#FF6329',
            color: '#FF6329'
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
          },
    }));

    const newRecordingClicked = () => {
        var win = window.open('https://form.jotform.com/221391781501047', '_blank');
        win?.focus();
    };

    const homeClicked = () => {
        navigate('/', { replace: true });
    };

    return(
        <div className={styles.sheetcontainer}>
            <div className={styles.navbar}>
                <Stack spacing={1} direction="row" className={styles.navbarlogostack}>
                    <Button className={styles.musicistbutton} onClick={() => {homeClicked();}}>
                        <MusicistImage className={styles.musicistimage}/>
                    </Button>
                </Stack>
                <Stack spacing={1} direction="row" className={styles.navbarbuttonstack}>
                    { performanceList.length > 0
                    && <FormControl sx={{ m: 1, minWidth: 120 }} size="small" className={styles.performancesdropdown}>
                        <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={String(currentPerformanceIndex)}
                            onChange={handleChange}
                        >
                            {performanceMenuItems()}
                        </Select>
                    </FormControl>}
                    <ColorButton 
                        variant="outlined" 
                        onClick={() => {newRecordingClicked();}}
                        startIcon={<CloudUploadIcon />}
                        >Upload recording
                    </ColorButton>
                </Stack>
            </div>
            {recordingUrl !== undefined
                && <ReactAudioPlayer 
                src={recordingUrl}
                onListen={onAudioListen} 
                listenInterval={100}
                onLoadedMetadata={onAudioLoadedMetadata}
                onPlay={onAudioPlay}
                onPause={onAudioPause}
                ref={(element) => { rap = element }}
            />}
            {compositionUrl !== undefined
                && <OSMDReact
                file={compositionUrl} // "Fr_Elise.mxl"
                measureIndex={measureIndex}
                onLoadedMetadata={onSheetLoadedMetadata}
                onMeasureClicked={onMeasureClicked}
            />}
            {editMode 
                && <div className={styles.synccontainer}>
                        {syncPointsLabels()}
                        <IconButton onClick={() => didClickAddSyncPoint()}><AddIcon /></IconButton>
                    </div>  
            }
            <div>
                {recordingUrl !== undefined
                && <WaveformReact 
                    file={recordingUrl}
                    playing={playing}
                    timestamp={playerTimestamp}
                    onWaveformClicked={onWaveformClicked}
                    measureClicked={measureClicked}
                    onWaveformUpdatedFollowingMeasureClicked={onWaveformUpdatedFollowingMeasureClicked}
                    onPlayButtonClicked={onPlayButtonClicked}
                    duration={audioDuration}
                />}
            </div>
            
        </div>
    );
}
